/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-18 18:49:48
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-18 19:03:48
 */
import Marriage from './marriage.vue'
export default Marriage
