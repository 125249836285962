/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-19 17:23:39
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-19 17:26:07
 */
export default {
  name: 'NoData'
}
