/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-18 22:17:40
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-24 19:35:00
 */
import echarts from 'echarts'
import NoData from '../noData'

export default {
  name: 'PieChart',
  components: {
    NoData
  },
  data () {
    return {
      // 画图基本配置
      baseOptions: {
        tooltip: {
          show: false
          // trigger: 'item',
          // formatter: '{a} <br/>{b}: {c} ({d}%)'
        }
        // series: [
        //   {
        //     name: '年龄',
        //     type: 'pie',
        //     radius: ['50%', '70%'],
        //     labelLine: {
        //       show: true,
        //       smooth: true
        //     },
        //     label: {
        //       color: '#333'
        //     },
        //     data: [
        //       { value: 335, name: '直接访问' },
        //       { value: 310, name: '邮件营销' },
        //       { value: 234, name: '联盟广告' },
        //       { value: 135, name: '视频广告' },
        //       { value: 1548, name: '搜索引擎' }
        //     ]
        //   }
        // ]
      },
      // 数据系列基本配置
      seriesBaseOptions: {
        name: '',
        type: 'pie',
        radius: ['48%', '60%'],
        labelLine: {
          show: true,
          smooth: true
        },
        label: {
          color: '#333'
        },
        hoverAnimation: false
      }
    }
  },
  props: {
    title: {
      type: String,
      default: '标题'
    },
    width: {
      type: Number,
      default: 500
    },
    height: {
      type: Number,
      default: 250
    },
    data: {
      type: Array,
      default: () => ([
        { value: 1, name: 'AA', color: '#47BDFF', endColor: '#2C68FF' },
        { value: 2, name: 'BB', color: '#58F6F9', endColor: '#14C6CA' },
        { value: 3, name: 'CC', color: '#EF50C1', endColor: '#F38AFF' },
        { value: 2, name: 'BB', color: '#8ED6FF', endColor: '#BFD1FF' },
        { value: 1, name: 'EE', color: '#E6E6E6', endColor: '#E6E6E6' }
      ])
    }
  },
  computed: {
    hasData () {
      return Array.isArray(this.data) && this.data.length > 0
    }
  },
  watch: {
    data () {
      // 开始绘图
      this.startChart()
    }
  },
  mounted () {
    // 开始绘图
    this.startChart()
  },
  methods: {

    /**
     * 生成线性渐变色
     * @param { string } color0 正常色值
     * @param { string } color1 正常色值
     */
    linearGradientColor (color0, color1) {
      return new echarts.graphic.LinearGradient(1, 0, 0, 0, [{
        offset: 0,
        color: color0
      }, {
        offset: 1,
        color: color1
      }])
    },

    /**
     * 颜色
     * @param { string } color 起始颜色
     * @param { string } endColor 终点颜色
     */
    color (color, endColor) {
      if (endColor) {
        return this.linearGradientColor(color, endColor)
      }
      return color
    },

    /**
     * 数据配置
     * @param { array } data 数据源
     */
    seriesData (data) {
      const self = this
      return data.map(({ name, value, color, endColor }) => {
        return {
          name,
          value,
          itemStyle: {
            color: self.color(color, endColor)
          }
        }
      })
    },

    /**
     * series配置
     * @param { string } title 标题
     * @param { array } data 数据源
     */
    seriesOptions (title, data) {
      const self = this
      return [Object.assign({}, self.seriesBaseOptions, { name: title, data: self.seriesData(data) })]
    },

    /**
     * 绘图总配置
     * @param { string } title 标题
     * @param { array } data 数据源
     */
    echartsOption (title, data) {
      const self = this
      return Object.assign({}, self.baseOptions, { series: self.seriesOptions(title, data) })
    },

    /**
     * 绘制环形图
     */
    startChart () {
      const echartsOption = this.echartsOption(this.title, this.data)
      const chartInstance = echarts.init(this.$refs.chart)

      chartInstance.setOption(echartsOption)
    }
  }
}
