/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-18 18:02:47
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-18 19:41:27
 */

export default {
  name: 'ChartContainer',
  props: {
    title: {
      type: String,
      defualt: '标题'
    }
  }
}
