/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-19 17:23:12
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-19 17:26:34
 */
import NoData from './noData.vue'
export default NoData
