/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-18 20:49:14
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-18 22:18:42
 */
import PieChart from './pieChart.vue'
export default PieChart
