/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-18 18:03:16
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-18 18:06:05
 */
import ChartContainer from './chartContainer.vue'
export default ChartContainer
