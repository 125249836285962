/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-12-07 09:43:07
 * @LastEditors: Arvin
 * @LastEditTime: 2020-12-07 11:44:51
 */

class ChartDataTool {
  constructor (sumRate = 10000) {
    this.sumRate = sumRate
    this.startRate = 0
    this.rate = 0
  }

  getRate (count, total) {
    this.rate = this.calcRate(count, total)
    this.startRate += Math.round(this.rate * 100)
    if (this.rate > 0 && this.startRate > this.sumRate) {
      this.rate = Math.round(Math.round(this.rate * 100) + this.sumRate - this.startRate) / 100
    }
    return this.rate
  }

  calcRate (count, total) {
    return (Math.round(count * 100000 / total) / 1000).toFixed(2) - 0
  }
}

export default ChartDataTool
